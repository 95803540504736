import { jsx as _jsx } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { forwardRef } from 'react';
const defineOpacity = (isOpen = false, maxHeight) => (maxHeight !== 0 ? ({
    opacity: 1,
}) : ({ opacity: isOpen ? 1 : 0 }));
const CollapsePanelStyled = styled.div((props) => (Object.assign({ maxHeight: props.isOpen ? '100vh' : props.maxHeight, transition: 'max-height 250ms, opacity 250ms', overflow: props.isOpen ? 'visible' : 'hidden' }, defineOpacity(props.isOpen, props.maxHeight))));
const CollapsePanelComponent = ({ destroyOnHide = false, maxHeight = 0, children, isOpen, }, ref) => (_jsx(CollapsePanelStyled, { maxHeight: maxHeight, ref: ref, isOpen: isOpen, children: destroyOnHide === true ? isOpen && children : children }));
const CollapsePanel = forwardRef(CollapsePanelComponent);
export default CollapsePanel;
